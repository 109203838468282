.Post-features {
  margin-top: 10px;
}

.notableFeature {
  font-size: 0.9rem;
}

.noVideoContainer {
  display: flex;
  flex-direction: column;
  height: 175px;
  align-content: center;
  justify-content: center;
}