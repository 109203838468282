.leads-list {
  display: flex;
  flex-direction: column;
}

.leads-toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  font-weight: 500;
  border-bottom: 1px solid #eeeef1;
  position: -webkit-sticky;
  position: sticky;
  padding-right: 16px;
  top: 0px;
}

.toolbarIcon {
  font-size: 2rem;
  color: #007aff;
}

.toolbarIcon:hover {
  cursor: pointer;
  color: #0063ce;
}

.toolbarIcon:active {
  color: #007aff;
  opacity: 0.25;
}

.leadStages {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  font-weight: 500;
  border-bottom: 1px solid #eeeef1;
  position: -webkit-sticky;
  position: sticky;
  top: 58px;
}

.leadStage {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 22px;
}

.leadStage:hover {
  background: #eeeef1;
  cursor: pointer;
}

.leadStage.active {
  background: #eeeef1;
  cursor: pointer;
}

.leftSide {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bookmark {
  font-size: 1.5rem;
  margin-right: 25px;
}

.leadStagesTitle {
  display: flex;
  font-size: 0.9rem;
  font-weight: 500;
}

.leadsCount {
  display: flex;
  font-size: 0.9rem;
  font-weight: 500;
}

.leads-search {
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.leads-search-input {
  background: #f4f4f8;
  padding: 8px 10px;
  border-radius: 10px;
  border: none;
  font-size: 14px;
}

.leads-search-input::placeholder {
  text-align: center;
}

.leads-search-input:focus::placeholder {
  text-align: left;
}