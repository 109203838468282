@import "~react-image-gallery/styles/css/image-gallery.css";
.draggable-container {
  display: table;
  white-space: nowrap;
  border: 0;
  background-color: transparent;
  margin: 0 auto;
}

.draggable-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.draggable-grid {
  float: left;
  width: 170px;
  height: 170px;
  padding: 8px;
  background: transparent;
  border: 0;
}

.draggable-element {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #f8f8f8;
}