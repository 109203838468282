.rightToolbarIcon {
  font-size: 1.8rem;
  color: #007aff;
  margin-right: 18px;
}

.rightToolbarIcon:hover {
  cursor: pointer;
  color: #0063ce;
}

.rightToolbarIcon.active {
  color: #007aff;
  opacity: 0.25;
}